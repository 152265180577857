import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Text, minWidth, fontWeight, FontWeights } from '@calm-web/design-system';

export const Wrapper = styled.div`
	background: ${palette('cardBackgroundColor')};
	grid-column: 2 / 4;
	margin-top: 28px;
	text-align: center;

	${minWidth('tablet')} {
		margin-top: 0;
	}
`;

export const Heading = styled(Text).attrs({
	el: 'h1',
	styleAs: 'h4',
})`
	color: ${palette('text')};
	${fontWeight(FontWeights.Regular)};
	margin-top: 0;
	margin-bottom: 32px;
`;
